import { dashboard } from './dashboard';
import { trips } from './trips';

import { masters } from './masters';
import { other } from './other';

//-----------------------|| MENU ITEMS ||-----------------------//

const menuItems = {
    items: [dashboard, trips, masters, other]
};

export default menuItems;
