import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

// project imports
import GuestGuard from './../utils/route-guard/GuestGuard';
import MinimalLayout from './../layout/MinimalLayout';
import NavMotion from './../layout/NavMotion';
import Loadable from '../ui-component/Loadable';

// login routing
const LoginHome = Loadable(lazy(() => import('../views/pages/authentication/authentication3/Home')));
const AuthLogin = Loadable(lazy(() => import('../views/pages/authentication/login')));
const AuthRegister = Loadable(lazy(() => import('../views/pages/authentication/register')));
const DTripSheet = Loadable(lazy(() => import('../Components/trips/DriverTripSheet')));
const OTPInput = Loadable(lazy(() => import('../Components/trips/OTPInput')));
//-----------------------|| AUTH ROUTING ||-----------------------//

const LoginRoutes = () => {
    const location = useLocation();

    return (
        <Route path={['/login', '/authlogin', '/register','/driver_trip', '/otp_input']}>
            <MinimalLayout>
                <Switch location={location} key={location.pathname}>
                    <NavMotion>
                        <GuestGuard>
                            <Route path="/login" component={LoginHome} />
							<Route path="/authlogin" component={AuthLogin} />
                            <Route path="/register" component={AuthRegister} /> 
                            <Route path="/driver_trip" component={DTripSheet} />
                            <Route path="/otp_input" component={OTPInput} /> 
                        </GuestGuard>
                    </NavMotion>
                </Switch>
            </MinimalLayout>
        </Route>
    );
};

export default LoginRoutes;
