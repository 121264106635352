import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

// project imports
import MainLayout from './../layout/MainLayout';
import Loadable from '../ui-component/Loadable';
import AuthGuard from './../utils/route-guard/AuthGuard';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('../views/pages/LandingPage')));
const TripList = Loadable(lazy(() => import('../Components/trips/triplist')));
const TripBooking = Loadable(lazy(() => import('../Components/trips/TripBooking')));
const TripSheet = Loadable(lazy(() => import('../Components/trips/TripSheet')));
const DriverTripSheet = Loadable(lazy(() => import('../Components/trips/DriverTripSheet')));
const TripView = Loadable(lazy(() => import('../Components/trips/TripView')));
const TripAllotment = Loadable(lazy(() => import('../Components/trips/TripAllocation')));
//const SearchBar = Loadable(lazy(() => import('../Components/SearchBar/SearchBar')));
const Logout = Loadable(lazy(() => import('../views/pages/authentication/Logout')));
//const Sample = Loadable(lazy(() => import ('../Components/BasicExampleDataGrid')));
const ClientList = Loadable(lazy(() => import ('../Components/clientList')));
const ClientData = Loadable(lazy(() => import ('../Components/ClientData')));
//const ClientEdit = Loadable(lazy(() => import ('../Components/ClientEdit')));
const ClientProjectList = Loadable(lazy(() => import ('../Components/ClientProjectList')));
const ClientProjectData = Loadable(lazy(() => import ('../Components/ClientProjectData')));
const DriverList = Loadable(lazy(() => import ('../Components/driverList')));
const DriverData = Loadable(lazy(() => import ('../Components/DriverData')));
const VehicleList = Loadable(lazy(() => import ('../Components/VehicleList')));
const VehicleData = Loadable(lazy(() => import ('../Components/VehicleData')));
const VehicleAssign = Loadable(lazy(() => import ('../Components/VehicleAssign')));
//const Sample2 = Loadable(lazy(() => import ('../Components/FullFeaturedCrudGrid')));
const ClientCommercialList = Loadable(lazy(() => import ('../Components/ClientCommercialList')));
const ClientCommercialData = Loadable(lazy(() => import ('../Components/clientCommercialData')));
const ClientProjectView = Loadable(lazy(() => import ('../Components/ClientProjectView')));
const VehicleView = Loadable(lazy(() => import ('../Components/VehicleView')));
const ClientView = Loadable(lazy(() => import ('../Components/ClientView')));
const DriverView = Loadable(lazy(() => import ('../Components/DriverView')));
const CommercialView = Loadable(lazy(() => import ('../Components/CommercialView')));
const DynamicList = Loadable(lazy(() => import ('../Components/DynamicList')));
const StylingRowsGrid = Loadable(lazy(() => import ('../ui-component/StylingRowsGrid')));
const UserManagementList = Loadable(lazy(() => import ('../Components/UserManagementList')));
const UserManagementData = Loadable(lazy(() => import ('../Components/UserManagementData')));
const UserManagementView = Loadable(lazy(() => import ('../Components/UserManagementView')));
const NotificationList = Loadable(lazy(() => import ('../Components/NotificationList')));
const UserResetPassword = Loadable(lazy(() => import ('../Components/UserResetPassword')));
const EmployeeList = Loadable(lazy(() => import ('../Components/EmployeeList')));
const EmployeeData = Loadable(lazy(() => import ('../Components/EmployeeData')));
const EmployeeView = Loadable(lazy(() => import ('../Components/EmployeeView')));


//-----------------------|| MAIN ROUTING ||-----------------------//

const MainRoutes = () => {
    const location = useLocation();

    return (
        <Route
            path={[
                '/dashboard/default',
                '/trip/triplist',
                '/trip/trip_booking',
				'/trip/trip_allotment',
                '/trip/trip_sheet',
                '/trip/trip_view',
                '/trip/driver_trip_sheet',
                '/style_grid',
                '/client_list',
                '/client_data',
                '/client_edit',
                '/client_view',
                '/client_project_list',
                '/client_project_data',
                '/client_project_view',
                '/driver_list',
                '/driver_data',
                '/driver_view',
                '/vehicle_list',
                '/vehicle_data',
                '/vehicle_view',
                '/vehicle_assign',
                '/client_commercial_list',
                '/client_commercial_data',
                '/commercial_view',
                '/dynamic_list',
                '/logout',
                '/user_management_list',
                '/user_management_data',
                '/user_management_view',
                '/notification_list',
                '/user_reset_password',
                '/employee_list',
                '/employee_data',
                '/employee_view',
            ]}>

            <MainLayout>
                <Switch location={location} key={location.pathname}>
                    <AuthGuard>
                        <Route path="/dashboard/default" component={DashboardDefault} />
                        <Route path="/trip/triplist" component={TripList} />
                        <Route path="/trip/trip_booking" component={TripBooking} />
                        <Route path="/trip/trip_allotment" component={TripAllotment} />
                        <Route path="/trip/trip_sheet" component={TripSheet} />
                        <Route path="/trip/trip_view" component={TripView} />
  						<Route path="/logout" component={Logout} />
                        <Route path="/client_list" component={ClientList} />
                        <Route path="/client_data" component={ClientData} />
                        <Route path="/client_project_list" component={ClientProjectList} />
                        <Route path="/client_project_data" component={ClientProjectData} />
                        <Route path="/driver_list" component={DriverList} />
                        <Route path="/driver_data" component={DriverData} />
                        <Route path="/vehicle_list" component={VehicleList} />
                        <Route path="/vehicle_data" component={VehicleData} />
                        <Route path="/client_commercial_list" component={ClientCommercialList} />
                        <Route path="/client_commercial_data" component={ClientCommercialData} />
                        <Route path="/vehicle_assign" component={VehicleAssign} />
                        <Route path="/client_view" component={ClientView} />
                        <Route path="/driver_view" component={DriverView} />
                        <Route path="/commercial_view" component={CommercialView} />
                        <Route path="/vehicle_view" component={VehicleView} />
                        <Route path="/client_project_view" component={ClientProjectView} />
                        <Route path="/dynamic_list" component={DynamicList} />
                        <Route path="/trip/driver_trip_sheet" component={DriverTripSheet} />
                        <Route path="/style_grid" component={StylingRowsGrid} />
                        <Route path="/user_management_list" component={UserManagementList} />
                        <Route path="/user_management_data" component={UserManagementData} />
                        <Route path="/user_management_view" component={UserManagementView} />
                        <Route path="/notification_list" component={NotificationList} />
                        <Route path="/user_reset_password" component={UserResetPassword} />
                        <Route path="/employee_list" component={EmployeeList} />
                        <Route path="/employee_data" component={EmployeeData} />
                        <Route path="/employee_view" component={EmployeeView} />
                    </AuthGuard>
                </Switch>
            </MainLayout>
        </Route>
    );
};

export default MainRoutes;

