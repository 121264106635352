// assets
import { IconBrandFramer, IconTypography, IconPalette, IconShadow, IconWindmill, IconLayoutGridAdd, IconUser, IconCar, IconSteeringWheel, IconBriefcase, IconFileText, IconListCheck } from '@tabler/icons';

// constant
const icons = {
    IconTypography: IconTypography,
    IconPalette: IconPalette,
    IconShadow: IconShadow,
    IconWindmill: IconWindmill,
    IconBrandFramer: IconBrandFramer,
    IconLayoutGridAdd: IconLayoutGridAdd,
    IconUser: IconUser,
    IconCar: IconCar,
    IconSteeringWheel: IconSteeringWheel,
    IconBriefcase: IconBriefcase,
    IconFileText: IconFileText,
    IconListCheck: IconListCheck,
};

//-----------------------|| securities MENU ITEMS ||-----------------------//
                
export const masters = {
    id: 'masters',
    title: 'Masters',
    type: 'group',
    children: [
        {
            id: 'myapi.view_tbldriver',
            title: 'Driver',
            type: 'item',
            url: '/driver_list',
            icon: icons['IconSteeringWheel'],
            breadcrumbs: false
        },
        {
            id: 'myapi.view_tblvehicle',
            title: 'Vehicle',
            type: 'item',
            url: '/vehicle_list',
            icon: icons['IconCar'],
            breadcrumbs: false
        },
        {
            id: 'myapi.view_tblclient',
            title: 'Clients',
            type: 'item',
            url: '/client_list',
            icon: icons['IconBriefcase'],
            breadcrumbs: false
        },
        {
            id: 'myapi.view_tblclientproject',
            title: 'Client Projects',
            type: 'item',
            url: '/client_project_list',
            icon: icons['IconFileText'],
            breadcrumbs: false
        },
        {
            id: 'myapi.view_tblclientcommercial',
            title: 'Client Commercials',
            type: 'item',
            url: '/client_commercial_list',
            icon: icons['IconListCheck'],
            breadcrumbs: false
        },
        {
            id: 'myapi.view_employee',
            title: 'Employee',
            type: 'item',
            url: '/employee_list',
            icon: icons['IconListCheck'],
            breadcrumbs: false
        },
        {
            id: 'auth.view_user',
            title: 'User Management',
            type: 'item',
            url: '/user_management_list',
            icon: icons['IconUser'],
            breadcrumbs: false
        }

    ]
};
