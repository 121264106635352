import PropTypes from 'prop-types';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import configData from '../../config';
import { LOGOUT } from '../../store/actions'  
//-----------------------|| AUTH GUARD ||-----------------------//

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }) => {
    const account = useSelector((state) => state.account);
    const { isLoggedIn, user, token } = account;
    const dispatcher = useDispatch();
    //console.log(children)
    if (!isLoggedIn) {
        return <Redirect to="/login" />;
    }else {
        try {
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            axios.get( configData.API_SERVER + 'home/', {})
            .then(function (response) {
                console.log('session valid', )
                /*if (response.status == 401) {
                    console.log('session expired', )
                    return <Redirect to="/login" />
                }*/
            })
            .catch((err)=> {
                if (err.response.status == 401){
                    console.log('session expired err' )
                    dispatcher({ type: LOGOUT });
                    return <Redirect to="/login" />
                }
            })
        }catch (err){
            console.log('not able check the authentication')

            return <Redirect to="/login" />
        }
    }
    return children;
};

AuthGuard.propTypes = {
    children: PropTypes.node
};

export default AuthGuard;
