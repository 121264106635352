import PropTypes from 'prop-types';
import React from 'react';

// material-ui
import { makeStyles } from '@material-ui/styles';
import { Avatar, Box, ButtonBase, Button, Typography } from '@material-ui/core';

// project imports
import LogoSection from '../LogoSection';
import SearchSection from './SearchSection';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';
import { drawerWidth } from '../../../store/constant';
import { Link as RouterLink } from 'react-router-dom';
import CarAnimation from '../../../Components/CarAnimation';
import config from '../../../config';

// assets
import { IconMenu2 } from '@tabler/icons';
import { useSelector } from 'react-redux';
// style constant
const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1
    },
    headerAvatar: {
        ...theme.typography.commonAvatar,
        ...theme.typography.mediumAvatar,
        transition: 'all .2s ease-in-out',
        background: theme.palette.secondary.light,
        color: theme.palette.secondary.dark,
        '&:hover': {
            background: theme.palette.secondary.dark,
            color: theme.palette.secondary.light
        }
    },
    boxContainer: {
        width: '200px',
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            width: 'auto'
        }
    }, 
    disp : {
        bgcolor:'primary.light'
    }
}));

//-----------------------|| MAIN NAVBAR / HEADER ||-----------------------//

const Header = ({ handleLeftDrawerToggle }) => {
    const classes = useStyles();
    const account = useSelector((state) => state.account);
    const { user } = account;
    return (
        <React.Fragment>
            {/* logo & toggler button */}
            <div className={classes.boxContainer}>
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
                <ButtonBase sx={{ borderRadius: '1px',  overflow: 'hidden' }}>
                    <Avatar variant="rounded" className={classes.headerAvatar} onClick={handleLeftDrawerToggle} color="inherit">
                        <IconMenu2 stroke={2.4} size="1.5rem" />
                    </Avatar>
                </ButtonBase>
            </div>
            <Typography variant="h1" component="div" sx={{ flexGrow: 1, align:"center", p:2, minWidth: 50, fontFamily:'serif', color:'navy' }}>
                {config.TITLE_TXT}
                <CarAnimation />
            </Typography>
           <label className='form-label'>{user}</label>
            <RouterLink to={'/logout'}>
                <Button variant="contained" >Logout</Button>
            </RouterLink>
        </React.Fragment>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
