// assets
import { IconBrandFramer, IconTypography, IconPalette, IconShadow, IconWindmill, IconLayoutGridAdd, IconBookmark, IconList, IconClipboardList } from '@tabler/icons';

// constant
const icons = {
    IconTypography: IconTypography,
    IconPalette: IconPalette,
    IconShadow: IconShadow,
    IconWindmill: IconWindmill,
    IconBrandFramer: IconBrandFramer,
    IconLayoutGridAdd: IconLayoutGridAdd,
    IconBookmark: IconBookmark,
    IconList: IconList,
    IconClipboardList: IconClipboardList,
};

//-----------------------|| securities MENU ITEMS ||-----------------------//
                
export const trips = {
    id: 'trips',
    title: 'Trips',
    type: 'group',
    children: [
        {
            id: 'myapi.add_tbltrip_booking',
            title: 'Booking',
            type: 'item',
            url: '/trip/trip_booking',
            icon: icons['IconBookmark'],
            breadcrumbs: false
        }, {
            id: 'myapi.view_tbltrip_list',
            title: 'Trip List',
            type: 'item',
            url: '/trip/triplist/',
            icon: icons['IconList'],
            breadcrumbs: false
        },
        {
            id: 'myapi.view_tbltrip_tripsheet',
            title: 'Trip Sheet',
            type: 'item',
            url: '/trip/triplist?trip_status=Assigned',
            icon: icons['IconClipboardList'],
            breadcrumbs: false
        }
    ]
};
