let BACKEND_SERVER = null;
if (process.env.REACT_APP_BACKEND_SERVER) {
  BACKEND_SERVER = process.env.REACT_APP_BACKEND_SERVER;
} else {
  BACKEND_SERVER = "https://travels.varnatravels.com/";  // "https://varna.esafetrip.com/";  //"http://127.0.0.1:8000/"; // 
}

const config = {
    // basename: only at build time to set, and don't add '/' at end off BASENAME for breadcrumbs, also don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default's
    basename: '',
    defaultPath: '/dashboard/default',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 3,
    API_SERVER: BACKEND_SERVER,
    TITLE_TXT: 'VARNA TRAVELS',
    clientURL: 'https://cabs.varnatravels.com/d/' 
};

export default config;
