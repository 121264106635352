import React from 'react';
import '../assets/scss/style.css';


const CarAnimation = () => {
  return (
      <div className="car">
      </div>
  );
};

export default CarAnimation;
