// action - state management
import { ACCOUNT_INITIALIZE, LOGIN, LOGOUT, AUTH_MENU } from './actions';

export const initialState = {
    token: '',
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

//-----------------------|| ACCOUNT REDUCER ||-----------------------//

const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACCOUNT_INITIALIZE: {
            const { isLoggedIn, user, token, permissions, authMenu, refresh } = action.payload;
            return {
                ...state,
                isLoggedIn:isLoggedIn,
                isInitialized: true,
                token:token,
                user : user,
                authMenu: authMenu,
                permissions:permissions,
                refresh: refresh,
                loginTime: new Date()
            };
        }
        case LOGIN: {
            const { user } = action.payload;
            return {
                ...state,
                isLoggedIn: true,
                user
            };
        }
        case LOGOUT: {
            return {
                ...state,
                isLoggedIn: false,
                token: '',
                user: null,
                authMenu: null,
                permissions:null

            };
        }
        case AUTH_MENU: {
            const { menuItem } = action.payload;
            return {
                ...state,
                menuItem 
            }
        }
        default: {
            return { ...state };
        }
    }
};

export default accountReducer;
